.dark-title {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 27px;
    float: left;
    color: #CCCCCC;
    margin-left: 2%;
}

.login-edit-title {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 27px;
    float: left;
    color: #515151;
    margin-left: 2%;
}

.dark-login-edit-title {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 27px;
    float: left;
    color: #CCCCCC;
    margin-left: 2%;
}

.v-profile-s-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 50px 0px #0000001A;
    width: 90%;
    margin: auto;
    margin-top: 10% !important;
}

.dark-v-profile-s-card {
    background: #262626;
    border-radius: 12px;
    box-shadow: 0px 0px 50px 0px #0000001A;
    width: 90%;
    margin: auto;
    margin-top: 10% !important;
}

.v-profile-last-card {
    position: relative;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 50px 0px #0000001A;
    width: 90%;
    margin: auto;
    margin-top: 5% !important;
    margin-bottom: 5% !important;
}

.dark-v-profile-last-card {
    position: relative;
    background: #262626;
    border-radius: 12px;
    box-shadow: 0px 0px 50px 0px #0000001A;
    width: 90%;
    margin: auto;
    margin-top: 5% !important;
    margin-bottom: 5% !important;
}

.dark-v-profile-td-card {
    background: #262626;
    border-radius: 12px;
    box-shadow: 0px 0px 50px 0px #0000001A;
    width: 90%;
    margin: auto;
    margin-top: 10% !important;
    margin-bottom: 5% !important;
}

.v-profile-td-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 50px 0px #0000001A;
    width: 90%;
    margin: auto;
    margin-top: 10% !important;
    margin-bottom: 5% !important;
}

.service-cat-chip {
    background-color: #E8F9FA;
    color: #515151;
    font-family: Avenir;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #16C1D0;
    padding: 5px;
    border-radius: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
    margin: auto;
}

.legal-info-label {
    text-align: left !important;
    display: block;
    margin-left: 2%;
}

.mt-0 {
    margin-top: 0% !important;
}

.dark-service-cat-chip {
    background-color: #fff;
    color: #515151;
    width: 90%;
    font-family: Avenir;
    font-size: 15px;
    font-weight: 500;
    margin-left: 3%;
    padding: 4px 3px 3px 3px;
    border-radius: 8px;
}

.view-more {
    --box-shadow: none;
    --background: #fff0;
    --color: #515151;
    box-shadow: none;
    background: #fff0;
    color: #515151;
    font-size: 16px;
    margin: 0;
    text-decoration: underline;
}


/* CSS for the dropdown list */


.dropdown-content {
    display: none;
}

.dropdown-content-avail {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 50px 0px #0000001A;
    z-index: 10;
    width: 95%;
    height: 230px;
    overflow-y: auto;
    margin: auto;
}

.dark-view-more {
    --box-shadow: none;
    --background: #fff0;
    --color: #515151;
    box-shadow: none;
    background: #fff0;
    color: #CCCCCC;
    font-size: 16px;
    margin: 0;
    text-decoration: underline;
}

.dropdown-content-avail-null {
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 0px 50px 0px #0000001A;
    z-index: 10;
    width: 95%;
    height: 230px;
    overflow-y: auto;
    margin: auto;
}

.dark-dropdown-content-avail-null {
    position: absolute;
    background-color: #262626;
    box-shadow: 0px 0px 50px 0px #0000001A;
    z-index: 10;
    width: 95%;
    height: 250px;
    overflow-y: auto;
    border: 1px solid #404040;
    margin: auto;
    border-radius: 5px;

}

.dark-dropdown-content-avail {
    position: absolute;
    background-color: #262626;
    box-shadow: 0px 0px 50px 0px #0000001A;
    z-index: 10;
    width: 95%;
    height: 230px;
    overflow-y: auto;
    border: 1px solid #404040;
    margin: auto;
    border-radius: 5px;

}

.dark-item {
    --background: #262626;
    color: #CCCCCC;
    border-bottom: 1px solid #404040;
}

.span-star-clr {
    color: #ff0000;
}

.post-code-mt {
    margin-top: 0% !important;
}