.request-review .review-holder
{
    margin-top: 20px;
}

.review-holder  .review-card
{
    background: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
}

.review-card ion-badge
{
    border-radius: 30px;
    height: 50px;
    margin-top: -20px !important;
    padding:20px !important;
}

.review-card .input-holder ion-button
{
    --background: transparent;
    --box-shadow: none;
    --color: var(--ion-color-primary);
}

.request-review .details .thumb img
{
    height: 20px;
}

.request-review .details ion-item ion-label h5
{
    font-family: 'Medium' !important;
    font-size: 200px !important;
}

.request-review-footer-toolbar ion-button
{
    --border-radius: 10px;
    height: 50px !important;
    font-family: 'Black';
    font-size: 20px;
}

.sendBtn
{
    --background: transparent;
    --box-shadow: none;
}