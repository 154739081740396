.skip-button {
    color: #262626 !important;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.main-row {
    height: 100% !important;
    width: 100% !important;
}


.intro .img-holder img {
    height: 100vw;
    margin-top: 20vw;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #16C1D0 !important;
}


.swiper-pagination-bullet {
    background: #818181 !important;
}

.swiper {
    height: 100% !important;
}

.slide-img {
    max-width: 100%;
    width: 49%;
    margin: auto;
    display: block;
    margin-top: 7vh !important;
    margin-bottom: 7vh !important;
}

.bottom-holder {
    background: white;
    bottom: 0;
    z-index: 20;
}


.slide-content .sm-detail {

    color: #77869E;
    font-family: 'Medium';
    font-size: 14px;
    margin-top: 10px !important;
    padding-bottom: 20px !important;
}


.intro-h4 {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #515151;
}

.intro-msg {
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    color: #515151;
}

.btns-holder {
    margin: 0 10px;
    padding-bottom: 10px !important;
}

.next-btn {
    --background: #16C1D0;
    font-family: Avenir;
    font-size: 15px;
    font-weight: 800;
}

.btn {
    --border-radius: 10px;
    display: inline-block !important;
    height: 3rem !important;
    margin: 0vh 5vh -10vh 5vh !important;
    text-transform: none;
    width: 45% !important;
}

#signup {
    --background: transparent;
    --border-color: #77869E;
    --border-size: 0.1px;
    --border-style: solid;
    --color: #77869E;
}


/* Dark Mode CSS Starts */
.dark-skip-button {
    color: #CCCCCC !important;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.dark-intro-h4 {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: center;
    color: #CCCCCC;
}

.dark-intro-msg {
    font-family: Avenir;
    font-size: 12px;
    font-weight: 500;
    color: #CCCCCC;
}

/* Dark Mode CSS Ends */