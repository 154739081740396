.custom-segment-button-unconfirmed {
    min-height: 3rem !important;
    text-transform: none !important;
    color: #515151;
    --background-checked: #FAEBE8;
    --color-checked: #D03716;
    font-family: Avenir !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 500;
}

.custom-segment-button-confirmed {
    min-height: 3rem !important;
    text-transform: none !important;
    color: #515151;
    --background-checked: #DCE9CD;
    --color-checked: #529003;
    font-family: Avenir !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 500;
}



.custom-segment-button-expired {
    min-height: 3rem !important;
    text-transform: none !important;
    color: #515151;
    --background-checked: #E6E6E6;
    --color-checked: #808080;
    font-family: Avenir !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 500;
}

.label-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 135px;
    display: inline-block !important;
}

ion-list {
    --background: #f8f9f9;
}

.ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    top: -12px;
    left: -12px;
}

.ribbon {
    font-size: 9px;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 5px 0;
    top: 19px;
    left: -32px;
    width: 120px;
    background-color: #FF8368;
    color: #fff;
}

.ribbon-modified {
    font-size: 10px;
    color: #333;
    text-align: center;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    position: relative;
    padding: 5px 0;
    top: 19px;
    left: -32px;
    width: 120px;
    background-color: #FEF6CD;
    color: #515151;
}

.booking-img {
    --border-radius: 0%;
    width: 80px;
    height: 80px;
    position: relative;
}

.booking-id-label {
    float: right;
    background-color: #56606B;
    color: #fff;
    width: 27%;
    padding: 3px 3px 2px 2px;
    border-radius: 6px;
    font-family: Avenir;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.postcode-label {
    color: #16C1D0;
    text-decoration: underline;
    font-family: Avenir;
    font-weight: 500;
    cursor: pointer;
}

.postcode-col {
    right: 0;
    left: 100%;
    position: sticky;
}

.first-title {
    font-size: 16px;
    color: #515151;
    font-family: Avenir;
    font-weight: 800;
    letter-spacing: 0em;
    line-height: 15px;
}

.first-div {
    padding: 9px 0px 0px 0px;
}

.second-col {
    padding: 3px 0px 0px 10px;
}

.text-ellipis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
}

.overall-list {
    width: 95%;
    margin: auto;
    margin-top: 2%;
    border-radius: 10px;
    padding: 2px;
    box-shadow: none;
}

hr {
    background-color: #F0F0F0;
}

.label-font-size {
    font-size: 14px;
}

.label-align {
    display: inline;
    vertical-align: middle;
}

.img-align {
    vertical-align: middle;
}

.middle-label {
    padding: 4px 0px 0px 4px;
    font-size: 13px;
    font-weight: 500;
    color: #515151;
    font-family: Avenir;
}

.call-icon {
    float: right;
}

.cat-person-icon {
    width: 8%;
}

.div-align {
    display: inline-block;
    line-height: 18px;
}

.expenses-bar {
    --background: #f8f9f9;
}

.booking-details-btn {
    --background: #16C1D0;
    color: #fff !important;
    min-height: 50px;
    --border-radius: 12px;
    font-family: Avenir;
    font-size: 13px;
    --box-shadow: none;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
}

.spanCount {
    background-color: red;
    color: #fff;
    max-width: 33px;
    font-size: 12px;
    display: flex;
    padding: 3px 6px 2px;
    border-radius: 6px;
    justify-content: center;
    margin: -4px 0px 0px 4px;
    align-items: center;
}

.light-mode-segements {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0;
    padding: 0;
}

.expenses {
    --background: #f8f9f9;
}

.top-item {
    --background: transparent;
}

.top-item .start-slot p {
    color: #77869e;
    font-family: 'Heavy';
    font-size: 0.9rem;
    margin: 0;
}

.top-item .start-slot h1 {
    color: #042c5c;
    font-family: 'Black';
    margin: 10px 0 0 0;
}

.top-item .end-slot p {
    font-family: 'Heavy';
    margin: 5px 0;
}

.top-item .end-slot #up {
    color: #1bc773;
}

.top-item .end-slot #down {
    color: #f24750;
}

.date-time-color {
    color: #515151;
    font-family: Avenir;
    font-weight: 500;
}

.sort-btn {
    background-color: #fff;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: 10px;
}

.postponed-batch {
    position: absolute;
    bottom: -10px;
    background: #007BFF;
    font-size: 11px;
    color: #FFFFFF;
    width: 80px;
    font-family: Avenir;
    font-weight: 500;
    text-align: center;
    padding: 2px;
    border-radius: 0px 0px 10px 10px;
}

.popup-ion-item {
    --background: #FFFFFF !important;
    margin: 0;
}

.popup-label {
    font-family: Avenir !important;
    font-size: 17px !important;
    font-weight: 500 !important;
}




/* Dark Mode CSS Starts */

.dark-sort-btn {
    background-color: #262626;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: 10px;
}

.dark-custom-segment-button-unconfirmed {
    min-height: 3rem !important;
    text-transform: none !important;
    --background-checked: #FAEBE8;
    --color-checked: #D03716;
    font-family: Avenir !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 500;
    --color: #CCCCCC !important;
}

.dark-custom-segment-button-confirmed {
    min-height: 3rem !important;
    text-transform: none !important;
    --background-checked: #DCE9CD;
    --color-checked: #529003;
    font-family: Avenir !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 500;
    --color: #CCCCCC !important;
}



.dark-custom-segment-button-expired {
    min-height: 3rem !important;
    text-transform: none !important;
    --background-checked: #E6E6E6;
    --color-checked: #808080;
    font-family: Avenir !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 500;
    --color: #CCCCCC !important;
}


.dark-overall-list {
    width: 95%;
    margin: auto;
    margin-top: 2%;
    border-radius: 10px;
    padding: 2px;
    box-shadow: none;
    background-color: #262626;
}

.dark-first-title {
    font-size: 16px;
    color: #CCCCCC;
    font-family: Avenir;
    font-weight: 800;
    letter-spacing: 0em;
    line-height: 15px;
}

.dark-middle-label {
    padding: 4px 0px 0px 4px;
    font-size: 13px;
    font-weight: 500;
    color: #CCCCCC;
    font-family: Avenir;
}

.dark-booking-id-label {
    float: right;
    background-color: #F0F0F0;
    color: #515151;
    width: 27%;
    padding: 3px 3px 2px 2px;
    border-radius: 6px;
    font-family: Avenir;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}


.dark-date-time-color {
    color: #CCCCCC;
    font-family: Avenir;
    font-weight: 500;
}

.dark-hr {
    background-color: #D9D9D914 !important;
}

.dark-popup-ion-item {
    --background: #212124 !important;
    background: #212124 !important;
    margin: 0;
    color: #CCCCCC;
}

.sort-count-badge {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    font-weight: bold;
    border-radius: 50px;
    color: #fff;
    max-width: 16px;
    font-size: 10px;
    display: flex;
    padding: 1px 6px 0px;
    justify-content: center;
    margin: -4px -8px 0px 0px;
    align-items: center;

}

/* Dark Mode CSS Ends */


.print-icon {
    font-size: 33px !important;
    margin: 0;
}

.print-btn {
    --border-radius: 10px;
    --box-shadow: none;
    --background: #004d40 !important;
}