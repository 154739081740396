.login-input {
    width: 100%;
    border: none;
}

.login-card-container ion-card {
    border-radius: 10px;
}

.login-card-container {
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 0;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    background-color: #fff;
}

.wrapper {
    position: fixed;
}

.login-logo {
    width: 19%;
}

.wlcm-title {
    font-family: Avenir;
    font-size: 26px;
    font-weight: 800;
    text-align: left;
    color: #262626;
}

.login-ion-item {
    width: 90%;
    margin: auto;
    --background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 5px;
}

/* Additional styling for the card */


.social-holder .btn {
    --background: transparent;
    --border-radius: 10px;
    --box-shadow: none;
    --border-color: #77869e56;
    --border-size: 0.05px;
    --border-style: solid;
    display: inline-block !important;
    height: 3rem !important;
    margin: 0 5px !important;
    text-transform: none;
}

.the-form {
    margin-top: 0vh;
}

.input-holder {
    margin: 10px 0;
}

.input-label {
    font-family: Avenir !important;
    font-size: 13px !important;
    font-weight: 500;
    margin: 0px 20px;
    line-height: 22px;
    color: #515151 !important;
}

.input-item {
    --highlight-color-invalid: red;
    --highlight-color-valid: #2AD95A;
}

.verify-tick {
    color: #2AD95A;
}


.login .rem-holder .rem-item {
    --padding-start: 0;
    --inner-padding-end: 0;
}

.remind-item {
    margin: 0;
    --background: #fff;
}

.remind-item-div {
    display: block;
    float: right;
}

.forgot-password {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    color: #515151;
    text-decoration: underline;
}


.login .rem-holder .rem-item .rem-check {
    margin-right: 10px !important;
}

.rem-holder {
    width: 90%;
    margin: auto;
}

.rem-label {
    font-family: Avenir !important;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #515151 !important;
}

.rem-holder .forgot-button {
    --background: transparent;
    --box-shadow: none;
    --color: var(--ion-color-primary) !important;
    font-size: 0.9rem !important;
    text-transform: none;
}


.btn-holder .login-button {
    --border-radius: 10px;
    height: 3rem !important;
    font-size: 16px;
    text-transform: uppercase;
    padding-bottom: 1px;
    --box-shadow: none;
}

.btn-holder {
    padding-bottom: 30px;
    padding-top: 15px;
}

.login-button {
    --background: #16C1D0;
    color: #fff;
    width: 90%;
    margin: auto;
}

.bottom-holder {
    margin-top: 20px;
}

.bottom-holder .bottom-btn {
    color: #77869E;
    font-size: 16px;
    margin: 0 !important;
}

.bottom-holder .bottom-btn-span {
    --background: transparent;
    --box-shadow: none;
    --color: #042C5C;
    font-family: 'Black';
    height: fit-content !important;
    font-size: 14px !important;
    text-transform: none;
}

.eye-icon {
    color: #515151;
    font-size: 22px;
}

.passwordHidden {
    -webkit-text-security: disc;
}

.passwordVisible {
    -webkit-text-security: none;
}

.create-account {
    
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    text-align: center;
    display: block;
    padding: 13px;
}

.create-account-span {

    font-size: 16px;
    font-weight: 500;
    color: #128777;
    
}


/* Dark Mode CSS Starts */


.dark-card-container {
    position: absolute;
    z-index: 1;
    width: 100%;
    bottom: 0;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    background-color: #212124;
}

.dark-card-container {
    position: fixed;
    z-index: 1;
    overflow: auto;
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
    padding: 20px 0px;
}

.dark-wlcm-title {
    font-family: Avenir;
    font-size: 26px;
    font-weight: 800;
    text-align: left;
    color: #CCCCCC;
}

.dark-input-label {
    font-family: Avenir !important;
    font-size: 13px !important;
    font-weight: 500;
    margin: 0px 20px;
    line-height: 22px;
    color: #CCCCCC;
}

.dark-login-ion-item {
    width: 90%;
    margin: auto;
    --background: #212124;
    border: 1px solid #C5C5C54D;
    border-radius: 5px;
}

.dark-eye-icon {
    color: #CCCCCC;
    font-size: 22px;
}

.dark-input {
    background: #212124;
    color: #CCCCCC;
}

.dark-forgot-password {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    color: #CCCCCC;
    text-decoration: underline;
}

.dark-remind-item {
    margin: 0;
    --background: #212124;
}

.dark-rem-label {
    font-family: Avenir !important;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #CCCCCC !important;
}


/* Dark Mode CSS Starts */