.product-detail-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

ion-img::part(image) {
    border-radius: 1rem;
}

.ion-label {
    color: #262626;
}

ion-content {
    --background: #F8F9F9;
}

table td:nth-child(1) {
    color: #515151;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}

table h6 {
    font-size: 13px;
    margin: 15px 15px;
}

table {
    width: 100%;
    margin: auto;
}

table td:nth-child(2) {
    color: #262626;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

}

tr:nth-child(even) {
    background-color: #F0F0F0;
}

.moneyy-holder ion-item {
    margin: 0 auto;
    width: 250px;
}

.moneyy-holder ion-item .start-slot {
    display: flex;
    align-items: center;
}

.moneyy-holder ion-item .start-slot h1 {
    margin: 0 !important;
}

.moneyy-holder ion-item ion-input {
    font-size: 2.5rem !important;
}

.addincome .unit-holder ion-item ion-select {
    margin-left: 30% !important;
}

.addincome .the-form .detail-item {
    margin: 15px 15px !important;
}

.detail-item {
    margin: 15px 15px !important;
}

.addincome .the-form .detail-item ion-label {
    font-size: 0.9rem;
}

.addincome .the-form .detail-item ion-textarea {
    --background: #dfe7f5;
    border-radius: 10px;
    padding: 0 10px;
}


.cat-holder {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    white-space: nowrap !important;
}

.cat-holder::-webkit-scrollbar {
    display: none !important;
}

.cat-holder ion-radio-group .rad-item {
    margin: 0 10px !important;
    width: 70px;
    display: inline-block !important;
}

.cat-holder ion-radio-group .rad-item ion-item {
    --min-height: 70px;
    --border-radius: 50% !important;
}

.cat-holder ion-radio-group .rad-item ion-item ion-radio {
    opacity: 0 !important;
}

.cat-holder .item-radio-checked {
    --background: #dfe7f5 !important;
    --color: var(--ion-color-primary) !important;
}



ion-footer .addincome-footer-toolbar ion-button {
    height: 50px !important;
}


/* Dark Mode CSS Starts  */

.dark-product-d-table {
    width: 100%;
    margin: auto;
}

.dark-product-d-table td:nth-child(1) {
    color: #A3A3A3;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}

.dark-product-d-table td:nth-child(2) {
    color: #CCCCCC;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

}

.dark-product-d-table tr:nth-child(even) {
    background-color: #262626;
}

/* Dark Mode CSS Ends  */



.prdt-dtls {
    font-size: 17px !important;
    font-weight: 600
}