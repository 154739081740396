@font-face {
    font-family: 'Avenir';
    src: url('/public/assets/imgs/images/fonts/AvenirLTStd-Book.otf') format('truetype');
    /* Replace with the correct file path and format for the regular font weight */
    font-weight: normal;
    font-style: normal;
}


body ion-content {
    font-family: Avenir;
    letter-spacing: 0.3px;
}



input:focus {
    outline: none;
}

.sc-ion-input-ios-h:not(.legacy-input) {
    min-height: 33px;
}

.ios ion-input {
    padding: 5px !important;
}

ion-button:hover {
    --background-hover: unset;
}

ion-button:focus {
    --background-activated: unset;
    --background-hover: unset;
    --background-focused: unset;
}

ion-button {
    --border-radius: 0px;
    --background-activated: unset;
    --background-hover: unset;
    --background-focused: unset;
}

.text-area-css:focus {
    outline: none;
}


.ios ion-tab-bar {
    height: 57px !important;
    bottom: 35px;
    padding: 11px;
}

.ios ion-item.styleS {
    --color: #fff;
}

.md .ios .select-icon {
    margin-inline: 4px 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    position: absolute !important;
    right: 1% !important;
}

.searchbar-search-icon.sc-ion-searchbar-md {
    top: 8px !important;
}

ion-input input textarea ion-textarea {
    scroll-behavior: smooth;
}


.searchbar-input.sc-ion-searchbar-md {
    padding-inline-end: 28px;
}




/* Dark Mode CSS Starts */

.dark-mode-tabs .tab-selected {
    --background: #212124 !important;
    --tab-background: var(--tab-background-dark);
    --tab-text-color: var(--tab-text-color-dark);
    color: #CCCCCC !important;
}

/* Apply the styles to IonTabBar and its components */
.dark-mode-tabs ion-tab-bar {
    --background: #212124 !important;
    background-color: var(--tab-background);
    color: var(--tab-text-color);
}

.dark-mode-header-toolbar {
    --background: #212124 !important;
}

.dark-mode-ion-content {
    --background: #212124 !important;
}

.dark-fontName {
    font-family: Avenir;
    color: #CCCCCC;
    letter-spacing: 0.3px;
}

/* Dark Mode CSS Ends */


/* Light Mode CSS Starts */

.light-mode-tabs {
    --tab-background: var(--tab-background-light);
    --tab-text-color: var(--tab-text-color-light);
}

.light-mode-tabs ion-tab-bar {
    background-color: var(--tab-background);
    color: var(--tab-text-color);
}


.light-mode-header-toolbar {
    --background: #f8f9f9 !important;
}

.light-mode-ion-content {
    --background: #f8f9f9 !important;
}

.fontName {
    font-family: Avenir;
    letter-spacing: 0.3px;
}

/* Light Mode CSS Ends */


.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    --background: transparent !important;
    box-shadow: none !important;
}

.custom-loading .loading-wrapper .loading-spinner {
    font-size: 48px;
    /* --color: #16C1D0 !important; */
}

.sc-ion-buttons-md-s .button-clear {
    --background-focused: none !important;
    --background-hover: none !important;
}

ion-select {
    --highlight-color-focused: none;
}

.text-area-focus:focus {
    outline: none;
}

.ahs-img {
    margin: -18vh auto -2vh;
    top: 0vh;
    width: 96%;
    position: relative;
    padding: 0px;
}

@media (max-width: 576px) {
    .ahs-img {
        margin: -18vh auto -2vh;
        top: 0vh;
        width: 96%;
        position: relative;
        padding: 0px;
    }

    .no-image-vd {
        width: 90%;
        height: 290px;
        margin: auto;
        object-fit: cover;
    }

    .header-logo {
        width: 35%;
        margin-left: 2vh;
    }

    .top-card {
        background: #fff;
        border-radius: 12px;
        box-shadow: 0px 0px 20px 0px #00000026;
        bottom: -50px;
        left: 5%;
        width: 90%;
        position: absolute;
        padding: 0px;
    }

    .dark-mode-bg {
        --background: #F8F9F9;
        margin-top: 6% !important;
    }

    .dark-item-mode-bg {
        --background: #212124;
        margin-top: 6% !important;
    }

    .dark-top-card {
        background: #262626;
        border-radius: 12px;
        box-shadow: 0px 0px 20px 0px #00000026;
        bottom: -50px;
        left: 5%;
        width: 90%;
        position: absolute;
        padding: 0px;
    }

    .m-d-none {
        display: none;
    }

    .d-flex-sign-up {
        display: block;
    }

    .ftr-jn-our-ntwrk-btn {
        width: 100%;
    }

    .lnd-header-img {
        width: 80%;
        margin: auto;
    }

}
.card-success {
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 95%;
    margin: auto;
    border-radius: 10px;
    padding: 10px;
}

@media (min-width: 768px) and (max-width: 1199px) {

    .lnd-header-img {
        width: 80%;
        margin: auto;
    }
    
    .ftr-jn-our-ntwrk-btn {
        width: 100%;
    }

    .d-flex-sign-up {
        display: block;
    }

    .m-d-none {
        display: none;
    }

    .product-detail-img {
        height: 200px;
        margin: 0;
        object-fit: cover;
        width: 200px;
    }

    .slider-holder .card {
        height: 20vw;
    }

    .border-top {
        border-top: none !important;
    }

    .border-right {
        border-right: none !important;
    }

    .d-first-div {
        padding: 4px 0px 0px 55px;
    }

    .no-network-img {
        width: 50%;
    }

    .ahs-img {
        margin: -18vh auto -2vh;
        top: 0vh;
        width: 50%;
        position: relative;
        padding: 0px;
    }
}

.border-right {
    border-right: 1px solid #F0F0F0;
}

.border-top {
    border-top: 1px solid #F0F0F0;
}

.dark-border-top {
    border-top: 1px solid rgba(217, 217, 217, 0.08);
}

.dark-border-right {
    border-right: 1px solid rgba(217, 217, 217, 0.08);
}

.change-pass-mt {
    margin-top: 15%;
}

.notification-card {
    border: 1px solid rgb(232, 249, 250);
    border-radius: 10px;
    width: 80%;
    float: right;
}

.reject-dark-opiton {
    background: #262626;
    --background: #262626;
    border: 1px solid #262626;
}

.product-image-add {
    margin-top: 4%;
}

@media (min-width: 1200px) {

        .video-text-details, .video-text-details p {
            padding-top: 0;
        }


    .signup-bg-content {
        --padding-start: 0 !important;
        --padding-end: 0 !important;
    }

    .card-success {
        width: 50%;
        padding: 50px;
    }

   .contact-holder {
        overflow-x: unset !important;
        overflow-y: unset !important;
    }

    .product-image-add {
        margin-top: 2%;
    }

    .dark-popup-ion-item:hover {
        --color: #CCCCCC;
    }

    .contact-holder .add-product {
        border-radius: 20px;
        padding: 58px;
        width: 100%;
    }

    .nocardh6 {
        margin-top: 2%;
    }

    .dark-nocardh6 {
        margin-top: 2%;
    }

    .notification-card {
        width: 100%;
    }

    .footer-btn {
        width: 70%;
    }

    .add-product-mt {
        display: block;
        margin-top: 5%;
    }

    .dash-width {
        width: 82%;
    }




    .bg-fp {
        background-image: url("/public/assets/imgs/images/afh-pro-desktop.jpg");
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .web-fp {
        position: relative;
        top: 50%;
        left: 75%;
        padding: 15px 0px 25px;
        transform: translate(-50%, -50%);
        width: 30%;
        box-shadow: 0px 5px 20px 0px #0000001A;
        background-color: #fff;
        border-radius: 25px;
    }

    .dark-web-fp {
        position: relative;
        top: 50%;
        left: 75%;
        padding: 15px 0px 25px;
        transform: translate(-50%, -50%);
        width: 30%;
        box-shadow: 0px 5px 20px 0px #0000001A;
        background-color: #212124;
        border-radius: 25px;
    }



    ion-modal#example-modal-reject {
        --height: 500px;
        --width: 50%;

    }

    .change-pass-mt {
        margin-top: 5% !important;
    }

    ion-modal#dark-example-modal-confirmed {
        --height: 380px;
        --width: 50%;
    }

    ion-modal#example-modal-confirmed {
        --height: 380px;
        --width: 50%;
    }

    ion-modal#dark-example-modal-reject {
        --height: 520px;
        --width: 50%;
    }

    ion-modal#example-modal {
        --width: 50%;
        --height: 350px;
    }

    ion-modal#dark-example-modal {
        --width: 50%;
        --height: 350px;
    }

    .ahs-img {
        margin: -18vh auto -2vh;
        top: 0vh;
        width: 40%;
        position: relative;
        padding: 0px;
    }

    .product-detail-img {
        height: 200px;
        margin: 0;
        object-fit: cover;
        width: 220px;
    }

    .slider-holder .card {
        height: 200px;
    }

    .text-ellipis {
        width: 500px;
    }

    .border-top {
        border-top: none;
    }

    .border-right {
        border-right: none;
    }

    .dark-border-top {
        border-top: none;
    }

    .dark-border-right {
        border-right: none;
    }

    .slide-img {
        margin-top: 0vh !important;
        margin-bottom: 0vh !important;
    }

    .gs-m-title {
        margin: auto !important;
        padding: 25px;
        text-align: center;
    }

    .getstarted-btn {
        position: unset;
        width: 80%;
        margin: auto;
    }

    .getstarted-msg {
        color: #fff;
    }

    .letsgrow-msg {
        color: #fff;
    }

    ion-grid.get-started-grid {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        height: 100%;
    }

    .d-d-none {
        display: none;
    }

    .notification-first-div {
        padding: 3px 0px 0px 20px;
    }

    .no-network-img {
        width: 25%;
    }

    .center-div {
        width: 80%;
        margin: auto;
    }

    .dark-login-card-container {
        padding: 10px;
    }

    .dark-afh-logo {
        width: 100px;
    }

    .dark-input-label {
        margin: 0px 15px;
    }

    .dark-login-ion-item {
        width: 98%;
    }

    .dark-rem-holder {
        width: 98%;
    }

    .dark-login-button {
        margin: auto;
    }

    .dark-header-logo {
        width: 200px;
        margin-left: 2vh;
    }

    .dark-top-card {
        bottom: -50px;
        left: 1%;
        width: 98%;
    }

    .login-card-container {
        padding: 10px;
        box-shadow: 0px 5px 20px 0px #0000001A;
        top: 50%;
        left: 75%;
        bottom: unset;
        width: 35%;
        transform: translate(-50%, -50%);
        border-radius: 25px;
    }

    .dark-card-container {
        padding: 10px;
        box-shadow: 0px 5px 20px 0px #0000001A;
        top: 50%;
        left: 75%;
        bottom: unset;
        width: 35%;
        transform: translate(-50%, -50%);
        border-radius: 25px;
    }

    .afh-logo {
        width: 100px;
        margin: auto !important;
    }

    .login-title {
        text-align: center;
    }

    .input-label {
        margin: 0px 15px;
    }

    .d-first-div {
        padding: 3px 0px 0px 55px;
    }

    .login-ion-item {
        width: 98%;
    }

    .rem-holder {
        width: 98%;
    }

    .login-button {
        margin: auto;
    }

    .header-logo {
        width: 200px;
        margin-left: 2vh;
    }

    .top-card {
        bottom: -50px;
        left: 1%;
        width: 98%;
    }

    .all-img {
        max-width: 90px;
    }

    .booking-sts-card {
        width: 98%;
    }

    .dark-booking-sts-card {
        width: 98%;
    }

    .overall-title {
        padding-top: 10px;
    }

    .overall-pounds {
        padding-bottom: 10px;
    }

    .first-top-card {
        padding: 0px 10px;
        padding-left: 0vh;
        top: 50px;
        width: 100%;
        position: absolute;
    }

    .third-dash-card {
        margin-top: 7% !important;
    }

    .info-grid {
        width: 99%;
        margin-top: 2%;
        padding: 5px;
        margin-bottom: 1%;
    }

    .top-card {
        background: #fff;
        border-radius: 12px;
        box-shadow: 0px 0px 20px 0px #00000026;
        bottom: -70px;
        left: 0;
        right: 0;
        width: 98%;
        margin: auto;
        position: absolute;
        padding: 0px;
    }

    .dark-top-card {
        bottom: -70px;
        left: 0;
        right: 0;
        width: 98%;
        margin: auto;
        position: absolute;
        padding: 0px;
    }

    .margin-top {
        margin-top: 5%;
    }

    .checkbx-div {
        margin-top: -1%;
    }

    .background-holder {
        background: #16C1D0;
        border-radius: 0 0 32px 32px;
        height: 160px;
        max-height: 100%;
    }

    .reject-booking-grid {
        width: 98%;
        margin: auto;
        margin-top: 2%;
    }

    .dark-reject-booking-grid {
        width: 98%;
        margin: auto;
        margin-top: 2%;
    }

    .dark-total-label-m {
        margin-bottom: 1%;
    }

    .dark-revenue-label {
        margin-bottom: 1%;
        margin-top: 1%;
    }

    .right-reject-count {
        position: inherit;
        color: red;
    }

    .table-m-title {
        margin: 2.2vh !important;
        margin-top: 4vh !important;
        margin-bottom: 4vh !important;
    }

    .missing-image-grid {
        width: 98%;
        margin-bottom: 3vh !important;
    }

    .table-dash {
        margin-top: 1%;
    }

    .m-title {
        margin: 25px 15px 15px;
        font-size: 15px;
    }

    .progressbar-holder {
        width: 250px;
        height: 250px;
        margin-left: 2%;
    }

    .total-label-m {
        font-size: 16px;
        margin-bottom: 1%;
    }

    .revenue-label {
        font-size: 16px;
        margin-top: 1%;
        margin-bottom: 1%;
    }

    .revenue-div {
        padding: 5px 7px;
        border-radius: 12px;
    }

    .revenue-div .total-label {
        padding-left: 4px;
        padding-top: 3px;
    }

    .unread-badge {
        margin-left: 5%;
    }

    .missing-img-right-arrow {
        top: 55%;
    }

    .btn-holder {
        padding-bottom: 10px;
    }

    .search-holder {
        width: 99%;
        margin: 0 auto;
    }

    .sort-btn {
        Width: 100%;
    }

    .booking-id-label {
        width: 85px;
        padding: 3px 5px 2px 5px;
    }

    .dark-booking-id-label {
        width: 85px;
        padding: 3px 5px 2px 5px;
    }

    .overall-list {
        width: 98%;
    }

    .segment-width {
        width: 98%;
    }

    .modified-chip {
        margin-left: 20px;
        width: 120px;
    }

    .confirmed-chip {
        margin-left: 20px;
        width: 120px;
    }

    .popup-confirmed-chip {
        margin-left: 20px;
        width: 120px;
    }

    .popup-modified-chip {
        margin-left: 20px;
        width: 120px;
    }

    .no-image-vd {
        width: 300px;
        height: 200px;
        margin: 0;
        margin-left: 20px;
        object-fit: cover;
    }


    .slide-content {
        width: 100%;
    }

    .status3-card {
        box-shadow: none;
        width: 98%;
        margin-top: 5px !important;
        margin: auto;
        padding: 0;
    }

    .popup-modified-chip {
        width: 120px;
        margin-left: 20px;
    }

    .model-container {
        width: 99%;
        margin-top: 10px !important;
        margin-bottom: 10px !important;
    }

    .checkbx-div {
        margin-left: 6px;
    }

    .checkbx-label {
        margin-left: 10px;
        margin-top: 4px;
    }

    .reject-btn {
        min-height: 49px;
        margin-left: 10px !important;
        width: calc(100% - 10px) !important;
    }

    .booking-details-btn {
        margin-right: 8px !important;
        width: calc(100% - 10px) !important;
    }

    .search-holder {
        width: 99%;
    }

    .segment-width {
        width: 97%;
    }

    .send-money-holder {
        width: 98%;
        margin: 0 auto;
    }

    .contact-holder .recip-card {
        height: 160px;
        padding: 20px 0 10px;
        min-width: 140px;
    }

    .empty-div {
        border: 1px solid #F0F0F0;
        margin-top: 30px !important;
        margin-bottom: 20px !important;
        margin: auto;
        width: 98%;
    }

    .dark-empty-div {
        border: 1px solid #F0F0F0;
        margin-top: 30px !important;
        margin-bottom: 20px !important;
        margin: auto;
        width: 98%;
    }

    .list-holder {
        width: 99%;
        margin: 0 auto;
    }

    .image-containers {
        width: 100%;
        height: 180px;
    }

    .profile-add {
        position: absolute;
        top: 27%;
        right: 48%;
        cursor: pointer;
    }

    .profile-top-card {
        box-shadow: 0px 10px 20px 0px #0000001A;
        margin: -16vh auto 0 auto;
        top: 2vh;
        width: 97%;
    }

    .profile .profile-top-card .img-holder .details p {
        margin-top: 0;
    }

    .status-chip {
        padding: 3px 15px;
        width: fit-content;
        margin: auto;
        margin-bottom: 20px;
    }

    .dark-mode-bg {
        background: #F8F9F9;
        margin-top: 4% !important;
    }

    .bottom-section {
        margin-top: 20px;
        padding: 0 25px;
    }

    .bottom-section .general {
        margin-top: 20px;
    }

    .change-password-img {
        width: 100px;
        margin: auto;
        margin-top: 2%;
    }

    .change-password-label {
        text-align: center;
        display: block;
        margin-top: 20px;
    }

    .v-profile-td-card {
        width: 97%;
        margin-top: 2% !important;
        margin-bottom: 2% !important;
    }

    .v-profile-last-card {
        width: 97%;
        margin-top: 2% !important;
        margin-bottom: 2% !important;
    }

    .dark-v-profile-td-card {
        width: 97%;
        margin-top: 2% !important;
        margin-bottom: 2% !important;
    }

    .dark-v-profile-last-card {
        width: 97%;
        margin-top: 2% !important;
        margin-bottom: 2% !important;
        z-index: -1;
    }

    .service-cat-chip {
        width: 100%;
    }

    .notification-img {
        margin: auto;
    }

    .card-style {
        margin-top: 3% !important;
        width: 60%;
    }

    .date-label {
        width: 30%;
    }

    .dark-date-label {
        width: 30%;
    }

    .dark-card-style {
        margin-top: 3% !important;
        width: 60%;
    }

    .dark-item-mode-bg {
        margin-top: 3% !important;
    }

    .capture-option {
        display: none;
    }

    .dark-contact-holder {
        overflow-x: unset !important;
        overflow-y: unset !important;
        display: flex;
        width: 100%;
    }

    .dark-contact-holder .dark-add-product {
        padding: 60px;
    }

    .dark-add-product {
        display: flex;
        width: 50%;
    }

    .contact-holder {
        display: flex;
        width: 100%;
    }

    .add-product {
        display: flex;
        width: 50%;
    }

    .add-btn-holder {
        width: 100%;
    }

    .image-container-add {
        width: 100%;
        height: 195px;
    }

    .addProductImg {
        width: 100%;
        height: 90%;
    }

    .dark-contact-holder .dark-add-product {
        width: 100%;
        height: unset;
    }

    .no-data-img {
        width: 30%;
        margin-top: 7% !important;
        margin: auto;
    }

    .web-fp {
        position: relative;
        top: 50%;
        left: 75%;
        padding: 15px 0px 25px;
        transform: translate(-50%, -50%);
        width: 30%;
        box-shadow: 0px 5px 20px 0px #0000001A;
        background-color: #fff;
        border-radius: 25px;
    }

    .model-container-signup {
        width: 99%;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }

    .btn-holder-signup {
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .form-control-model-fp-signup {
        padding: 10px 45px 10px 13px;
    }

    .windows .form-control-model-fp-signup {
        font-size: 13px;
        padding: 8px 45px 8px 13px;
    }

    .windows .model-container-signup,
    .windows .model-container {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
    }

    .windows .checkbx-label,
    .windows .btn-holder .login-button {
        font-size: 14px;
    }

    .windows .create-account {
        padding: 10px 10px 5px;
        font-size: 14px;
    }

    .windows .eye-icon-signup {
        top: 20px;
    }

    .eye-icon-signup {
        top: 25px;
    }

    .signup-toolbar {
        padding: 5px 20px;
    }

    .the-form.signup-grid {
        position: relative;
        top: 50%;
        left: 75%;
        transform: translate(-50%, -50%);
        width: 30%;
        box-shadow: 0px 5px 20px 0px #0000001A;
        background-color: #fff;
        border-radius: 25px;
    }

    .label-outline-model-fp-signup {
        background-color: #fff;
    }

    .sign-up-services-input {
        padding: 5px 10px 5px 13px !important;
    }

    .bg-signup {
        background-image: url('../public/assets/imgs/images/sign_up_hero_image.jpg');
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100vh;
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .bg-fp {
        /* background-image: url('../public/assets/imgs/images/gd-password-image.jpg'); */
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .gradient-overlay {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          180deg, 
          rgba(0, 0, 0, 0.5) 31.9%, 
          rgba(255, 255, 255, 0.5) 83.4%
        );
        pointer-events: none; /* This makes sure that click events pass through the overlay */
      }

    .background-img-container-login {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
    }

    .login-card-container {
        top: 50%;
        left: 75%;
        bottom: unset;
        width: 33%;
        transform: translate(-50%, -50%);
        border-radius: 25px;
    }



    .login-img {
        display: none;
    }


}

.toolbar-footer-bg {
    --background: #fff0;
}


.img-404 {
    width: 15%;
    text-align: center;
    display: block;
    margin: 1% 40%;
}

.card-404 {
    box-shadow: 0px 5px 20px 0px #0000001A;
    width: 50%;
    margin-top: 15% !important;
    margin: auto;
    border-radius: 10px;
    padding: 50px;
}

.h4-404 {
    font-family: Poppins;
    font-weight: 800;
    line-height: 20px;
    text-align: center;
    color: #333333;
}

.p-404 {
    font-family: Poppins;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: #333333;
}