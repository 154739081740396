.background-img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.background-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

ion-content.overflow {
    --offset-top: 0px !important;
    --offset-bottom: 0px !important;
    --keyboard-offset: 0px !important;
}

ion-content.background-img {
    position: relative;
    z-index: 1;
}

.getstarted-title {
    position: relative;
}

.getstarted-msg {
    font-family: Avenir;
    font-size: 26px;
    font-weight: 800;
    text-align: left;
    color: #262626;
}

.letsgrow-msg {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;

}

.afh-logo {
    position: relative;
    display: block;
    width: 30%;
    /* height: 35%; */
}

.getstarted-btn {
    --background: #16C1D0;
    font-weight: 800;
    color: #fff;
    font-family: Avenir;
    --border-radius: 8px;
    min-height: 50px;
    position: fixed;
    bottom: 19px;
    width: 86%;
}


.lnd-header {
    --background: #16C1D0 !important;
    --min-height: 80px;;
}

.lnd-flx{
    display: flex;
    justify-content: space-between;
    width: 65%;
    margin: auto;
}

.lnd-btns-div {
    position: relative;
    right: 0;
    display: flex;
}

.lnd-header-img {
    width: 28%;
}

.lnd-header-btns {
    --background: #fff;
    --color: #16c1d0;
    --border-radius: 5px;
    min-height: 40px;
    min-width: 60%;
    --box-shadow: none;
}

  @keyframes backgroundScrollVertical {
   
      100% {
          background-position: -1000px 3000px;
      }
  }
  
  .animated-background {
    animation: backgroundScrollVertical 100s linear infinite;
    background-image: url("/public/assets/imgs/images/bg-pattern-blue.svg");
    height: 40vh;
    overflow: hidden;
    position: relative;
    width: 100%;
    transition: 2s;
  }
  
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
  }

  .lnd-banner-title {
    color: #464242;
    text-align: center;
    font-size: 50px;
    padding-bottom: 50px;
    text-transform: initial;
    font-weight: 700;
  }

  .lnd-banner-btn {
    --background: #16C1D0 !important;
    --box-shadow: none;
    --border-radius: 5px;
    width: 40%;
    font-size: 16px;
    min-height: 45px;
    margin-left: 10px;
  }
  
  .lnd-second-grid {
    background-color: #ededed;
    padding: 75px 50px;
    margin: 0px -17px -5px -17px;
  }

  .lnd-mrgn-row {
    width: 70%;
    margin: auto;
  }
  

  .video-text-details {
    padding: 25px;
  }

  .lnd-bsn-col {
    padding: 100px 50px;
    max-width: 45% !important;
    background-color: #16C1D0;
  }
  
  .lnd-store-btns {
    width: 37%;
  }

  .lnd-store-btns-div {
    margin: auto;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10% !important;
  }

  .lnd-bsn-title {
    padding-bottom: 10px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 29px;
  }

  .red-block-container-p {
    color: #fafafa;
    font-weight: 300;
    padding: 5px 0;
    font-size: 16px;
}

.footer-grid-cmpy {
    background: #ededed;
    padding: 30px 0;
    margin: 0px -17px -17px -17px;
}

.ftr-jn-our-ntwrk-btn {
    width: 30%;
    margin-left: 1%;
    --background: #16C1D0;
    color: #fff;
    font-size: 14px;
    --box-shadow: none;
    min-height: 50px;
    --border-radius: 5px;
}

.ftr-jn-our-ntwrk-p {
    font-size: 16px;
    color: #464242;
    font-weight: 300;
    margin: 0;
}

.footer-hire-cmpy {
    color: #464242;
    font-size: 28px;
    text-align: center;
    margin: 0;
    font-weight: 600;
    padding: 25px;
    display: block;
}

  .partners-phone-container, .phones-container, .red-block-container {
    padding: 25px;
}

  .partners-phone-container, .phones-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    width: 100%;
    margin-top: 5%;
    margin-left: 5%;
    max-height: 100%;
    margin-bottom: 5%;
    }

  .lnd-icon {
    width: 5%;
  }

  .lnd-icon-grid {
    background-color: #ededed;
    margin: -5px -17px 0px -17px;
  }

  .lnd-main-title {
    text-align: center;
    font-size: 28px;
    padding: 50px 0;
    text-transform: uppercase;
    font-weight: 600;
  }

  .lnd-icon-div {
    display: flex;
    align-items: center;
  }

  .lnd-icon-label {
    margin-left: 2%;
    text-transform: uppercase;
    color: #464242;
    font-weight: 600;
    font-size: 16px;
  }

  .lnd-icon-p {
    font-size: 16px;
    font-weight: 300;
    Color: #464242;
    margin-top: 2%;
    display: block;
  }

  .market-titile {
    padding-bottom: 10px;
    color: #464242;
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .ftr-app-btns {
    display: flex;
    align-items: center;
    margin: auto;
  }

  .ftr-play-btn {
    width: 73%;
    margin-left: 3%;
  }

  .ftr-title {
    font-weight: 600;
    font-size: 17px;
  }

  .ftr-a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    margin: 0 0 5px;
    transition: all .3s;
    font-weight: 300;
    font-size: 17px;
  }

  .frt-last-grid {
    background-color: #56606b;
    padding: 0px 0;
    margin: 17px -17px 0px -17px;
    position: relative;
    bottom: -17px;
  }

.mobo-word {
    color: #cfcfcf;
    text-decoration: none;
}

.mobo-word:hover {
    color: rgb(255, 0, 0) !important;
    text-decoration: none;
}


.footer-grid {
    background-color: #2e3b4e; /* Dark background */
    color: #ffffff; /* White text */
    padding: 20px 0;
  }
  
  .footer-links,
  .social-links {
    list-style: none; /* Remove dots */
    padding: 0;
    margin: 0;
  }
  
  .footer-links li,
  .social-links li {
    margin-bottom: 10px;
  }
  
  .footer-links a,
  .social-links a {
    color: #ffffff; /* White text */
    text-decoration: none; /* Remove underline */
  }
  
  .footer-links a:hover,
  .social-links a:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  .social-links li img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
  }
  
  .copyright {
    text-align: center;
    font-size: 14px;
    color: #cfcfcf;
    margin-top: 20px;
  }
  
  h4 {
    font-size: 16px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  
  .feedback-button {
    position: fixed;
    right: 10px;
    top: 50%;
    background-color: #ff0000;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    transform: rotate(-90deg);
    transform-origin: right center;
  }
