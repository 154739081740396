.change-password-img {
    width: 35%;
    margin: auto;
    margin-top: 25%;
}

.change-password-label {
    text-align: center;
    display: block;
    margin-top: 5%;
}

.form-control-model-fp {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 10px;
    background-color: #fff0;
    color: #262626;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    min-height: 25px;
}

.label-outline-model-fp {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 2;
    margin: -5px 0px -10px 13px;
    font-size: 12px;
    transition: font-size 0.2s;
    color: #262626;
}

.dark-label-outline-model-fp {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #212124;
    z-index: 2;
    margin: -5px 0px -10px 13px;
    font-size: 12px;
    transition: font-size 0.2s;
    color: #CCCCCC;
}

.footer-toolbar-clr {
    --background: #f8f9f9 !important;
}

.submit-fp {
    --background: #16C1D0;
    color: #fff !important;
    min-height: 50px;
    --border-radius: 12px;
    font-family: Avenir;
    font-size: 15px;
    --box-shadow: none;
    font-weight: 800;
    line-height: 15px;
    width: 90%;
    margin: auto;
    margin-bottom: 3%;
    letter-spacing: 0em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 15%;
}

.dark-change-password-label {
    color: #CCCCCC;
    text-align: center;
    display: block;
    margin-top: 5%;
}

.dark-form-control-model-fp {
    width: 100%;
    padding: 10px;
    border: 1px solid #C5C5C54D;
    border-radius: 10px;
    background-color: #fff0;
    color: #CCCCCC;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    min-height: 25px;
}