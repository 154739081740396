.send-fp {
    min-height: 50px;
    --border-radius: 12px;
    font-size: 15px;
      
    line-height: 15px;
    letter-spacing: 0.4px;
    text-align: center;
}

.form-control-model-otp {
    width: 70%;
    padding: 15px;
    border-radius: 10px;
    background-color: #fff0;
    border: 2px solid #E6E6E6CC;
    color: #333333;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    min-height: 25px;
}


.resend-otp {
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    color: #128777;
    text-decoration: underline;
    text-align: center;
    display: block;
    margin-bottom: 3%;
}

.mt-5 {
    margin-top: 8% !important;
}

.primary-button {
    --background: #16C1D0;
    color: #fff;
    width: 100%;
    margin: auto;
}