.label-outline-model-fp-signup {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f8f9f9;
    z-index: 2;
    margin: -7px 0px -10px 25px;
    font-size: 12px;
    transition: font-size 0.2s;
    color: #262626;
}

.form-control-model-fp-signup {
    padding-right: 30px;
    margin: auto;
    padding: 13px 45px 13px 13px;
    display: block;
    width: 100%;
    border: 2px solid #E6E6E6CC;
    border-radius: 10px;
    background-color: #fff0;
    color: #262626;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    min-height: 30px;
}


.d-title {
    font-size: 17px;
    text-align: center;
    display: block;
    color: #333;
}

.model-container-signup {
    position: relative;
    margin: auto;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    width: 97%;
}

.windows .d-title {
    font-size: 14px;
}

.windows .label-outline-model-fp-signup {
    font-size: 11px;
}

.d-flex-sign-up {
    display: flex;
    align-items: center;
}

.footer-toolbar {
    background: #65727f;
    color: #fff;
    padding: 25px 0px 0px 0px;
    margin: 0px -17px -17px -17px;
    position: relative;
    bottom: -17px;
  }
  
  .footer-links,
  .social-links {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  
  .footer-links a,
  .social-links a {
    color: white;
    text-decoration: none;
  }
  
  .app-store-buttons {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .feedback-button {
    position: fixed;
    right: 10px;
    top: 50%;
    background-color: #ff0000;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    transform: rotate(-90deg);
    transform-origin: right center;
  }
  
  .copyright {
    text-align: center;
    margin: 20px 0;
    font-size: 14px;
    color: #cfcfcf;
  }

  .social-m-icon{
    width: 40%;
  }
  .social-m-icon-div {
    display: flex;
  }

 .copy-right {
    display: flex;
    align-items: center;
    justify-content: center;
 }

 .btm-row {
    background-color: #56606b;
    padding: 20px 0;
 }

 .top-title {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
 }

 .sign-up-btn {
    --background: #667280;
    --border-radius: 5px;
    min-height: 45px;
    width: 95%;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
    margin: auto;
    --box-shadow: none;
 }

 ion-button {
    --box-shawdow:none;
 }

 .bottom-btn-sign-up {
    width: 95%;
    min-height: 45px;
    --border-radius: 5px;
    --box-shadow: none;
    margin: auto;
    margin-top: 2% !important;
    margin-bottom: 2% !important;
 }