.notifications .section {
    margin: 10px 0 !important;
}

.notifications .section .section-title h4 {
    color: #c1c7d0 !important;
}

.notifications .section .the-list ion-item .thumb img {
    border-radius: 50%;
    height: 70px;
    width: 70px;
}

.notifications .section .the-list ion-item ion-label h5 {
    color: #002251 !important;
    font-family: 'Medium' !important;
}

.notifications .section .the-list ion-item ion-label h5 .amount {
    color: var(--ion-color-primary) !important;
}

.notifications .section .the-list ion-item .end-slot {
    margin-left: 0 !important;
}

.custom-segment-button-notification {
    min-height: 3rem !important;
    text-transform: none !important;
    color: #515151;
    --background-checked: #F8F9F9;
    --color-checked: #16C1D0;
    font-family: Avenir !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 800;
}

.notifications-title {
    font-family: Avenir;
    font-size: 17px;
    color: #515151;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
}

.notification-img {
    width: 50px;
    height: 50px;
    display: block;
    object-fit: inherit;
    object-position: inherit;
}

.notification-msg {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #515151;
    letter-spacing: 0em;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.notification-date-time {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #515151;
}

.notificationunread {
    background: #E1FDFF;
    margin-top: 2%;
    border-radius: 10px;
    cursor: pointer;
}

.notificationread {
    margin-top: 2%;
    cursor: pointer;
}

.notificationunread:hover {
    background: #a7f9ff;
}

.notificationread:hover {
    background: #a7f9ff;
    border-radius: 10px;
}

.dark-notificationunread:hover {
    background: #1e2f41;
}

.dark-notificationread {
    border-radius: 10px;
    margin-top: 2%;
    cursor: pointer;
}

.dark-notificationread:hover {
    background: #1e2f41;
    border-radius: 10px;
    margin-top: 2%;
    cursor: pointer;
}

/* Dark Mode CSS starts */


.dark-custom-segment-button-notification {
    min-height: 3rem !important;
    text-transform: none !important;
    color: #CCCCCC !important;
    --color: #CCCCCC !important;
    --background-checked: #212124;
    --color-checked: #16C1D0;
    font-family: Avenir !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 800;
}

.dark-notificationunread {
    background: #0F1924;
    margin-top: 2%;
    border-radius: 10px;
    cursor: pointer;
}

.dark-notification-msg {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #CCCCCC;
    letter-spacing: 0em;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.dark-notification-date-time {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #808080;
}