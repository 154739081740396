.dash-main-title {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0em;
    color: #fff;
    text-align: left;
}

.dash-title {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

.CircularProgressbar-path {
    transform: rotate(0.5turn) !important;
}

.unread-badge {
    border-radius: 7px;
    --padding-bottom: 3px;
    background: #FFFFFF;
    color: #16C1D0;
    --padding-top: 4px;
    margin-left: 5%;
    padding: 4px 5px 3px;
}

.header-toolbar {
    background: #18c1d0;
}

.unread-div {
    display: flex;
    align-items: center;
    padding: 8px 10px;
}

.unread-img {
    position: absolute;
    right: 15px;
}

.unread-msg {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
}

.header-logo {
    width: 47%;
    margin-left: 2vh;
}

.bell-icon-m {
    padding: 0;
    margin: 0 !important;
}

.table-m-title {
    margin: 2.2vh !important;
    margin-top: 3vh !important;
    margin-bottom: 3vh !important;
}

.table-main-title {
    font-size: 18px !important;
}

.right-reject-count {
    position: absolute;
    right: 4%;
}

.overall-pounds {
    font-size: 18px;
    line-height: 22px;
    font-family: Avenir;
    font-weight: 800;
    letter-spacing: 0em;
    color: #515151;
    bottom: 5px;
    position: absolute;
    display: block;
}

.overall-title {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #515151;
    letter-spacing: 0em;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.total-div {
    display: flex;
    align-items: center;
}

.total-label {
    margin-left: 2%;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #515151;
}

.grid-p {
    padding: 5px;
}

.revenue-div {
    background: #16C1D0;
    color: #FFFFFF;
    width: fit-content;
    padding: 5px;
    border-radius: 12px;
}

.month-label {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    color: #515151;
}

.home-toolbar {
    --border-width: 0;
    --background: transparent !important;
    --color: white !important;
    position: absolute;
    width: 100%;
}

#senicn {
    font-size: 30px !important;
}

.home-toolbar ion-title {
    margin-left: 60px;
}

.margin-top {
    margin-top: 16%;
}

.home {
    --background: #f8f9f9 !important;
}

ion-footer {
    --background: #f8f9f9 !important;
}

.background-holder {
    background: #16C1D0;
    border-radius: 0 0 32px 32px;
    height: 165px;
    max-height: 100%;
}

.first-top-card {
    padding: 0px 10px;
    padding-left: 0vh;
    top: 65px;
    width: 100%;
    position: absolute;
}

.top-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 20px 0px #00000026;
    bottom: -40px;
    left: 5%;
    width: 90%;
    position: absolute;
    padding: 0px;
}

.booking-sts-card {
    background: #FFFFFF;
    border-radius: 12px;
    width: 90%;
    margin: auto;
    box-shadow: 0px 0px 20px 0px #00000026;
    padding: 2px;
}

.booking-sts-title {
    font-family: Avenir;
    font-size: 18px;
    color: #515151;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.booking-sts-div {
    display: flex;
    align-items: center;
}

.blue-square {
    height: 13px;
    width: 13px;
    border-radius: 3px;
    background-color: #007BFF;
}

.yellow-square {
    height: 13px;
    width: 13px;
    border-radius: 3px;
    background-color: #FFC107;
}

.info-title {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0em;
    color: #515151;
    text-align: left;
}

.info-msg {
    font-family: Avenir;
    font-size: 13px;
    margin-top: 2%;
    font-weight: 500;
    line-height: 15px;
    color: #515151;
    letter-spacing: 0em;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.reject-title {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0em;
    color: #fff;
    margin-left: 15px;
    text-align: left;
}

.reject-msg {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
    letter-spacing: 0em;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.missing-title {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0em;
    margin-left: 15px;
    color: #fff;
    text-align: left;
}

.missing-msg {
    font-family: Avenir;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
    letter-spacing: 0em;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.top-card .top-row p {
    color: #77869E;
    font-family: 'Roman';
    font-size: 13px;

}

.total-label-m {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 4%;
    display: block;
    color: #515151;
}

.revenue-label {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    display: block;
    margin-top: 10%;
    margin-bottom: 4%;
    color: #515151;
}

.info-grid {
    border: 1px solid #16C1D0;
    width: 94%;
    margin: auto;
    border-radius: 10px;
    margin-top: 4%;
    padding: 5px;
    margin-bottom: 2%;
}

.reject-booking-grid {
    box-shadow: 0px 0px 20px 0px #00000026;
    background: #FF6666;
    color: #fff;
    width: 90%;
    margin: auto;
    margin-top: 5%;
    border-radius: 12px;
}

.dash-td {
    padding: 10px;
}

.dash-td-title {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 0em;
    color: #FFFFFF !important;
}

.table-dash {
    box-shadow: 0px 0px 20px 0px #00000026;
    border-radius: 12px;
    margin-top: 3%;
}

.missing-image-grid {
    background-color: #729196;
    box-shadow: 0px 0px 20px 0px #00000026;
    color: #fff;
    width: 90%;
    border-radius: 12px;
    margin-bottom: 3vh !important;
}

.icon-btn-bell {
    --padding-end: 12px !important;
}

.right-arrow {
    position: absolute;
    right: 45%;
}

.info-img {
    width: 90%;
    margin-left: 10%;
}

.top-card .top-row p strong {
    color: #042C5C;
    font-family: 'Heavy' !important;
}

.top-card .top-row ion-button {
    font-family: 'Heavy' !important;
}

.precentage-row {
    margin: 8px 0px 0px 35px;
}

.home .bottom-section {
    padding: 0 16px;
}

.home .bottom-section .btns-holder ion-button {
    --background: #dfe7f5;
    --border-radius: 12px !important;
    --box-shadow: none;
    --color: #0047cc;
    height: 50px !important;

}

.home .bottom-section .btns-holder ion-button ion-icon {
    font-size: 25px;

}

.progressbar-holder .progress-image {
    height: 30px;
}

.progressbar-holder .spent-holder {
    color: "#77869E";
    font-size: 13px;
    margin-top: 10px;

}

.progressbar-holder .bottom-detail {
    margin-top: 50px;
}

.bottom-detail p {
    font-size: 12px;
}

.details .the-title h3 {
    color: #262626;
    font-family: 'Heavy';
    font-size: 14px;
}

.the-list ion-item {
    --border-radius: 10px;
    margin: 10px 0 !important;
}

.the-list ion-item .start-slot {
    margin-right: 16px;
}

.the-list ion-item .start-slot img {
    height: 40px;
}

.the-list ion-item h5 {
    color: #ee5a55 !important;
    font-family: 'Heavy' !important;
}




.the-list ion-item ion-label h4 {
    color: #042C5C !important;
    font-family: 'Heavy' !important;
    font-size: 16px;
}

.the-list ion-item ion-label p {
    color: #77869E !important;
    font-family: 'Roman' !important;
    font-size: 13px;
    margin: 0 !important;
    padding-bottom: 0 !important;
}

.split-up-heading {
    border-radius: 12px 0px 0px;
    background: rgb(22, 193, 208) !important;
    font-family: Avenir !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    line-height: 15px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: #fff !important;
    padding: 12px;
}

.revenue-heading {
    border-radius: 0px !important;
    background: rgb(22, 193, 208) !important;
    font-family: Avenir !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    line-height: 15px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    padding: 12px;
    color: #fff !important;
}

.bkng-count {
    border-radius: 0px 12px 0px 0px !important;
    background: rgb(22, 193, 208) !important;
    padding: 12px;
    font-family: Avenir !important;
    font-size: 15px !important;
    font-weight: 800 !important;
    line-height: 15px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
    color: rgb(255, 255, 255) !important;
}




/* Dark Mode CSS Starts */

.dark-home {
    --background: #212124 !important;
}

.dark-top-card {
    background: #262626;
    border-radius: 12px;
    box-shadow: 0px 0px 20px 0px #00000026;
    bottom: -40px;
    left: 5%;
    width: 90%;
    position: absolute;
    padding: 0px;
}

.dark-overall-title {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #CCCCCC;
    letter-spacing: 0em;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.dark-overall-pounds {
    font-family: Avenir;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0em;
    color: #CCCCCC;
    display: block;
    bottom: 5px;
    position: absolute;
}

.dark-booking-sts-card {
    background: #262626;
    border-radius: 12px;
    width: 90%;
    margin: auto;
    box-shadow: 0px 0px 20px 0px #00000026;
    padding: 2px;
}

.dark-booking-sts-title {
    font-family: Avenir;
    font-size: 18px;
    color: #CCCCCC;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.dark-info-title {
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0em;
    color: #CCCCCC;
    text-align: left;
}

.dark-info-msg {
    font-family: Avenir;
    font-size: 13px;
    font-weight: 500;
    margin-top: 2%;
    line-height: 17px;
    color: #CCCCCC;
    letter-spacing: 0em;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.dark-reject-booking-grid {
    box-shadow: 0px 0px 20px 0px #00000026;
    background: #FF6666;
    color: #fff;
    width: 90%;
    margin: auto;
    margin-top: 5%;
    border-radius: 12px;
}

.dark-dash-main-title {
    font-family: Avenir;
    font-size: 20px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0em;
    color: #CCCCCC;
    text-align: left;
}

.dark-table td:nth-child(1) {
    color: #CCCCCC;
    background: #D9D9D908;
}

.dark-table td:nth-child(2) {
    color: #CCCCCC;
    background: #D9D9D908;
}

.dark-table td:nth-child(3) {
    color: #CCCCCC;
    background: #D9D9D908;
}

.dark-table tr:nth-child(even) {
    background-color: #262626;
}

.dark-table {
    box-shadow: 0px 0px 20px 0px #00000026;
    border-radius: 12px;
    margin-top: 3%;
}

.dark-month-label {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
    color: #CCCCCC;
}

.dark-total-label-m {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 4%;
    display: block;
    color: #CCCCCC;
}

.dark-total-label {
    margin-left: 2%;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #CCCCCC;
}

.monthly-main-title {
    font-size: 16px !important;
}

.dark-revenue-label {
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    display: block;
    margin-top: 10%;
    margin-bottom: 4%;
    color: #CCCCCC;
}

.dark-total-label-badge {
    margin-left: 2%;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    color: #FFF;
}

.dark-revenue-div {
    background: #16C1D0;
    color: #FFFFFF;
    width: fit-content;
    padding: 5px;
    border: 2px solid #E8F9FA;
    border-radius: 12px;
}


/* Dark Mode CSS Ends */





.no-network-div {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-network-img {
    width: 60%;
}

.missing-img-right-arrow {
    right: 4% !important;
}

.welcome-close-btn {
    --background: #16C1D0;
    color: #fff !important;
    min-height: 50px;
    --border-radius: 12px;
    font-size: 13px;
    width: 50%;
    margin: auto;
    --box-shadow: none;
    font-weight: 800;
    position: relative;
    top: 85%;
    bottom: 0px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
}

ion-modal#welcome-modal {
    --max-height: 100%;
    --height: 270px;
    --width: 40%;
    padding: 10px;
    --border-radius: 30px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}