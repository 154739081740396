.successmodal {
    --background: var(--ion-color-primary) !important;
}
.successmodal h2 {
    margin: 0 !important;
    color: #16C1D0 !important;
}
@keyframes scaleAnimation {
    0% {
        opacity: 0;
        transform: scale(1.5);
   }
    100% {
        opacity: 1;
        transform: scale(1);
   }
}
@keyframes drawCircle {
    0% {
        stroke-dashoffset: 151px;
   }
    100% {
        stroke-dashoffset: 0;
   }
}
@keyframes drawCheck {
    0% {
        stroke-dashoffset: 36px;
   }
    100% {
        stroke-dashoffset: 0;
   }
}
@keyframes fadeOut {
    0% {
        opacity: 1;
   }
    100% {
        opacity: 0;
   }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
   }
    100% {
        opacity: 1;
   }
}
#successAnimationCircle {
    stroke-dasharray: 151px 151px;
    stroke: #16C1D0;
}
#successAnimationCheck {
    stroke-dasharray: 36px 36px;
    stroke: #16C1D0;
}
#successAnimationResult {
    fill: #16C1D0;
    opacity: 0;
}
#successAnimation.animated {
    animation: 1s ease-out 0s 1 both scaleAnimation;
}
#successAnimation.animated #successAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
}
#successAnimation.animated #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
}
#successAnimation.animated #successAnimationResult {
    animation: 0.3s linear 0.9s both fadeIn;
}
