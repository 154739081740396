.wallet-toolbar ion-title {
    color: #262626;
    font-family: 'Heavy';
    font-size: 19px;
}

.wallet-toolbar ion-title span {
    font-family: 'Roman';
    font-size: 13px;
}

.wallet-toolbar .end-btn {
    font-size: 30px;
}

.wallet {
    --background: #f8f9f9;
}


.slider-holder {
    margin-top: 20px;
}

#primary {
    background: #f8f9f9;
    color: white;
}

#gray {
    background: #DFE7F5;

}

#gray p {
    color: black;
}

.slide-content {
    width: 100%;
}

.slider-holder .card {
    border-radius: 1rem;
    height: 50vw;
    width: 100%;
    font-family: 'Medium';
}

.slider-holder .card .card-logo-holder {
    padding-top: 2vh;
}

.card-logo-holder img {
    height: 5vh;
}

.card-number-holder p {
    font-family: 'Courier';
    font-size: 16px;
    margin-bottom: 0;
}

.card-details p {
    font-family: 'Courier';
    margin-bottom: 0;
}

.progress {
    margin-top: 10px;
}

.progress ion-label {
    color: #042C5C;
    font-family: 'Heavy';
    font-size: 13px;
}

.progress ion-progress-bar {
    --progress-background: #4DF1A1;
    --border-radius: 2.5px;
    --buffer-background: #F24750;
    margin-top: 10px;
}



.statics ion-item {
    --background: transparent;
}

.statics p {
    font-family: 'Heavy';
    color: #77869E;
    font-size: 13px;
}

.statics h3 {
    font-family: 'Heavy';
    color: #042C5C;
    font-size: 16px;
}

.col-p0 {
    padding: 3px;
}

.send-money-holder {
    margin-top: 20px;
}

.send-money-holder .contact-holder {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    white-space: nowrap;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */

}

.send-money-holder .contact-holder::-webkit-scrollbar {
    display: none;
}

.nocardh6 {
    color: rgb(81, 81, 81);
    text-align: center;
    margin-top: 11%;
}

.dark-nocardh6 {
    color: #CCCCCC;
    text-align: center;
    margin-top: 11%;
}

.contact-holder .recip-card {
    background: white;
    border: 1px solid #dfe7f5;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    display: inline-block;
    height: 130px;
    margin: 0 10px;
    color: #515151;
    padding: 10px 0;
    min-width: 120px;
}

.contact-holder .recip-card .add-btn-holder {
    height: 64px;
    margin: 0 auto;
    padding-top: 10px;
}

.contact-holder .recip-card .add-btn-holder img {
    height: 2.5rem;
}

.contact-holder .recip-card ion-avatar {
    margin: 0 auto;
}

.contact-holder .recip-card p {
    color: #77869e;
    font-family: 'Medium';
    font-size: 0.9rem;
    margin: 5px 0 0 0;
}

.slide-img-title {
    margin: 6px;
}


.header-icons {
    font-size: 24px !important;
}

.search-bar {
    --background: #fff;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    width: 100%;
    line-height: 15px;
    color: #515151;
    box-shadow: none;
    padding: 0px;
}

.empty-div {
    border: 1px solid #F0F0F0;
    margin-top: 7% !important;
    margin-bottom: 2% !important;
    margin: auto;
    width: 90%;
}

.product-img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.image-containers {
    width: 100%;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-containers img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-title-card {
    box-shadow: 0px 0px 1px 0px #00000040;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #fff;
    color: #515151;
}

.text-ellipis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 132px;
    display: block;
}

.image-title {
    font-size: 13px;
    padding: 10px;
    font-family: Avenir;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #515151;
}

.scroll-image-title {
    font-size: 13px;
    padding: 5px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    font-family: Avenir;
}

.segment-width {
    width: 95%;
    margin: auto;
    display: block;
}

.p-0 {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding: 0;
}

.p-add-title {
    padding-inline-start: var(--ion-padding, 14px);
}

.link-decoration {
    text-decoration: none;
}

.light-ion-title {
    color: #262626;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
}

.custom-segment-button {
    min-height: 3rem !important;
    text-transform: none !important;
    --background-checked: #E8F9FA;
    --color-checked: #16C1D0;
    font-family: Avenir !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 500;
}




/* Dark Mode CSS Starts */

.dark-ion-title {
    color: #CCCCCC;
    font-family: Avenir;
    font-size: 18px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
}

.dark-search-bar {
    --background: #262626;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    width: 100%;
    line-height: 15px;
    color: #CCCCCC;
    box-shadow: none;
    padding: 0px;
}

.dark-custom-segment-button {
    min-height: 3rem !important;
    text-transform: none !important;
    --background-checked: #262626;
    --color-checked: #16C1D0;
    font-family: Avenir !important;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 500;
    --color: #CCCCCC;
}

.dark-empty-div {
    margin-top: 7% !important;
    margin-bottom: 2% !important;
    margin: auto;
    border: 1px solid #D9D9D914;
    width: 90%;
}

.dark-image-title-card {
    box-shadow: 0px 0px 1px 0px #00000040;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #262626;
    color: #CCCCCC;
}

.dark-image-title {
    font-size: 13px;
    padding: 10px;
    font-family: Avenir;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #515151;
}

#darkPrimary {
    background: #212124;
    color: #212124;
}

/* Dark Mode CSS Ends */


.no-data-img {
    width: 55%;
    margin-top: 15% !important;
    margin: auto;
}

.add-product-btn {
    --background: #fff0;
    padding: 0;
    margin: 0;
    box-shadow: none;
}

.button-native {
    padding: 0 !important;
}