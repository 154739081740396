.top-logo {
    width: 20%;
    margin: 0;
}
.top-grid {
    background: linear-gradient(45deg, #f24654, #93213a) !important;
    padding: 75px;

}

.bkg-id-title {
    border: none;
    font-size: 18px;
    padding: 10px 5px 5px;
}

.top-grid-col {
    display: flex;
    justify-content: space-between;
}

.top-grid-btn {
    --background: #004d40 !important;
    --box-shadow: none;
    --border-radius: 5px;
    cursor: pointer;
}

/* .second-grid {
    padding: 24px;
    border-radius: 5px;
    top: 20%;
    position: absolute;
    margin: 0px 90px 0px 90px;
    background: #fff;
    box-shadow: 0px -2px 14px 0px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
} */

.top-div {
    margin: 0 auto;
    padding: 0;
    border-radius: 5px;
    top: 450px;
    width: 90%;
    transform: translate(-50%, -40%);
    left: 50%;
    position: absolute;
    background: #fff;
    box-shadow: 0px -2px 14px 0px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.innner-col {
    background: #fff;
    box-shadow: 0px -2px 14px 0px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    border-radius: 5px;
    padding: 15px 10px;
}

.td-p {
    line-height: 28px !important;
    font-size: 14px !important;
    padding: 5px;
    width: 50%;
}

.top-ion-title {
    font-weight: 600;
    padding: 0px 10px 0px 10px;
    font-size: 16px;
}

.inner-small-col {
    max-height: fit-content;
}

.title-icon {
    width: 5%;
    padding: 0px 0px 5px 0px;
}

.title-div {
    border-bottom: 1px solid #aaa;
    display: flex;
    align-items: center;
}

.inner-col-mt {
    margin-top: 2%;
}

.top-title-d-flex {
 display: flex;
}



@media print {
    body {
        -webkit-print-color-adjust: exact !important; /* For Webkit browsers */
        print-color-adjust: exact !important; /* For modern browsers */
    }

    .top-logo {
        width: 50%;
    }

    .td-p {
        line-height: 24px !important;
        padding: 0;
    }


    .title-icon {
        width: 8%;
        padding: 0px 0px 5px 0px;
    }
    

    .top-grid {
        background: linear-gradient(45deg, #f24654, #93213a) !important;
        color: #fff;
        padding: 80px 25px;
    }

    .innner-col {
        background: #fff !important; /* Ensure white background for inner sections */
    }

    .top-div {
        background: #fff !important;
        top: 420px;
    }

    /* Hide specific elements in print */
    .top-grid-btn {
        display: none !important;
    }
}