.profile-toolbar {
    --border-width: 0;
    --background: #0047CC;
    --color: white;
}

.profile {
    --background: #F8F9F9;
}

.profile .profile-top-card {
    padding-bottom: 1px !important;
}

.profile .profile-top-card .img-holder {
    padding-top: 10px;
}

.profile-header-toolbar {
    --background: #18c1d0 !important;
}

.thumb {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 0;
}

.profile-label {
    font-family: Avenir !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    color: #515151 !important;
    margin-left: 3%;
}

ion-toggle {
    --handle-background-checked: #16C1D0 !important;
    --background-checked: #C5C5C54D !important;
}

.profile .profile-top-card .img-holder ion-avatar {
    margin: 0 auto;
    --border-radius: 50px;
}


.profile-top-card .badge-holder {
    margin: 0 20px;
    background: #f8f9f9;
    border-radius: 12px;
    padding: 10px 0;
}

.dark-mode-bg {
    --background: #F8F9F9;
    margin-top: 10% !important;
}

.profile-top-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0px 0px 50px 0px #0000001A;
    margin: -18vh auto 0 auto;
    top: 2vh;
    width: 90%;
    position: relative;
    padding: 0px;
}

.profile-top-card .badge-holder .badge {
    display: inline-block;
    margin: 0 5px;
}

.bottom-section {
    margin-top: 30px;
}

.bottom-section .general {
    margin-top: 30px;
}

.bottom-section h5 {
    color: #262626;
    font-family: Avenir;
    font-size: 20px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.width100 {
    width: 100%;
    padding: 0;
    margin: 0;
}

.mail-to {
    width: 100%;
    text-decoration: none;
}

.profile-ion-item {
    border-radius: 12px;
    margin-top: 0px;
    --background: white;
}

.toggle-icon {
    background: #C5C5C54D !important;
}


.bottom-section .general .gen-list ion-item {
    border-radius: 12px;
    margin-top: 0px;
    --background: white;
}

.bottom-section .general .gen-list ion-item ion-label h2 {
    color: #042c5c;
}

.bottom-section .general .gen-list ion-item ion-label p {
    color: #77869e;
    font-family: 'Roman';
}


/* Dark Mode CSS Stats */

.dark-profile {
    --background: #212124 !important;
}

.dark-profile-top-card {
    background: #262626 !important;
}

.dark-name-label {
    color: #CCCCCC !important;
    font-family: Avenir !important;
    font-size: 20px;
    font-weight: 800;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}



.dark-item-mode-bg {
    --background: #212124;
    margin-top: 10% !important;
}

.dark-profile-label {
    font-family: Avenir !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    color: #CCCCCC !important;
    margin-left: 3%;
}

.dark-email-label {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #CCCCCC;
}

.dark-bottom-section {
    color: #CCCCCC !important;
}


.dark-bottom-section .general .gen-list ion-item {
    border-radius: 12px;
    margin-top: 0px;
    --background: #262626;
}

.dark-bottom-section .general .gen-list ion-item ion-label h2 {
    color: #042c5c;
}

.dark-bottom-section .general .gen-list ion-item ion-label p {
    color: #77869e;
    font-family: 'Roman';
}

.dark-bottom-section {
    margin-top: 30px;
}

.dark-bottom-section .general {
    margin-top: 30px;
}


.dark-profile-ion-item {
    border-radius: 12px;
    margin-top: 1px;
    --background: #262626;
}

.status-chip {
    background-color: #96C155;
    color: #fff;
    padding: 3px;
    width: 50%;
    border-radius: 20px;
    font-family: Avenir;
    font-size: 13px;
    font-weight: 500;
    margin: auto;
    margin-bottom: 5%;
}

.profile-add {
    position: absolute;
    top: 27%;
    right: 40%;
}

.dark-action-sheet {
    --background: #212124;
    --button-color: #CCCCCC;
    --color: #CCCCCC;
}

/* .clicked {
    background-color: #18c1d0;
    color: #fff;
} */
.mrgn-btn {
    margin-top: 5px !important;
}

.mrgn-btn-ahs {
    margin-top: 10px !important;
}